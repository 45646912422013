var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"deep-orange lighten-5",style:({
    height: '100%',
    backgroundImage: 'url(' + require('@/assets/material2.jpg') + ')',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{staticClass:"mt-6",attrs:{"width":"80%","max-width":"500"}},[_c('v-card-title',[_vm._v("注册医疗机构")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"医疗机构名称","persistent-hint":"","rules":[
              function (v) { return !!v || '此项必填'; },
              function (v) { return /[\u4e00-\u9fa5]{1,}/.test(v) || '请使用至少一个中文字符'; } ]},model:{value:(_vm.editItems.username),callback:function ($$v) {_vm.$set(_vm.editItems, "username", $$v)},expression:"editItems.username"}}),_c('v-text-field',{attrs:{"label":"医疗机构等级","rules":[function (v) { return !!v || '此项必填'; }]},model:{value:(_vm.editItems.jgdj),callback:function ($$v) {_vm.$set(_vm.editItems, "jgdj", $$v)},expression:"editItems.jgdj"}}),_c('v-radio-group',{attrs:{"mandatory":"","label":"医疗机构性质"},model:{value:(_vm.editItems.jgxz),callback:function ($$v) {_vm.$set(_vm.editItems, "jgxz", $$v)},expression:"editItems.jgxz"}},_vm._l((['公立', '民营']),function(n){return _c('v-radio',{key:n,attrs:{"label":(n + "医院"),"value":n}})}),1),_c('v-radio-group',{attrs:{"mandatory":"","label":"医疗机构类别"},model:{value:(_vm.editItems.jglb),callback:function ($$v) {_vm.$set(_vm.editItems, "jglb", $$v)},expression:"editItems.jglb"}},_vm._l(([
                '综合医院',
                '中医医院',
                '中西医结合医院',
                '专科医院',
                '社区卫生服务中心' ]),function(n){return _c('v-radio',{key:n,attrs:{"label":("" + n),"value":n}})}),1),_c('v-text-field',{attrs:{"label":"密码","type":"password","rules":[
              function (v) { return !!v || '此项必填'; },
              function (v) { return v.length >= 2 || '密码长度不够'; } ]},model:{value:(_vm.editItems.password),callback:function ($$v) {_vm.$set(_vm.editItems, "password", $$v)},expression:"editItems.password"}}),_c('v-text-field',{attrs:{"label":"确认密码","type":"password","rules":[
              function (v) { return !!v || '此项必填'; },
              function (v) { return v == _vm.editItems.password || '密码不一致'; } ]},model:{value:(_vm.editItems.repassword),callback:function ($$v) {_vm.$set(_vm.editItems, "repassword", $$v)},expression:"editItems.repassword"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","x-large":"","color":"primary","dark":"","type":"submit"}},[_vm._v("注册")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }