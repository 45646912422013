<template>
  <v-container
    fluid
    class="deep-orange lighten-5"
    :style="{
      height: '100%',
      backgroundImage: 'url(' + require('@/assets/material2.jpg') + ')',
      backgroundSize: 'cover',
    }"
    ><v-row align="center" justify="center"
      ><v-card class="mt-6" width="80%" max-width="500"
        ><v-card-title>注册医疗机构</v-card-title>
        <v-form ref="form" @submit.prevent="submit">
          <v-card-text>
            <!-- {{ editItems }} -->

            <v-text-field
              label="医疗机构名称"
              v-model="editItems.username"
              persistent-hint
              :rules="[
                (v) => !!v || '此项必填',
                (v) => /[\u4e00-\u9fa5]{1,}/.test(v) || '请使用至少一个中文字符',
              ]" />

            <v-text-field
              label="医疗机构等级"
              v-model="editItems.jgdj"
              :rules="[(v) => !!v || '此项必填']" />

            <v-radio-group mandatory label="医疗机构性质" v-model="editItems.jgxz"
              ><v-radio
                v-for="n in ['公立', '民营']"
                :key="n"
                :label="`${n}医院`"
                :value="n"
            /></v-radio-group>

            <v-radio-group mandatory label="医疗机构类别" v-model="editItems.jglb"
              ><v-radio
                v-for="n in [
                  '综合医院',
                  '中医医院',
                  '中西医结合医院',
                  '专科医院',
                  '社区卫生服务中心',
                ]"
                :key="n"
                :label="`${n}`"
                :value="n"
            /></v-radio-group>
            <!-- {{ editItems.password.length}} -->
            <v-text-field
              label="密码"
              v-model="editItems.password"
              type="password"
              :rules="[
                (v) => !!v || '此项必填',
                (v) => v.length >= 2 || '密码长度不够',
              ]" /><v-text-field
              label="确认密码"
              v-model="editItems.repassword"
              type="password"
              :rules="[
                (v) => !!v || '此项必填',
                (v) => v == editItems.password || '密码不一致',
              ]"
          /></v-card-text>
          <v-card-actions>
            <v-btn block x-large color="primary" dark type="submit"
              >注册</v-btn
            ></v-card-actions
          ></v-form
        ></v-card
      ></v-row
    ></v-container
  >
</template>
<script>
import qs from "qs";

export default {
  data: () => ({ editItems: { password: "" } }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.axios
          .post("register", qs.stringify(this.editItems))
          .then((e) => {
            alert("注册成功");
            this.$router.push({ name: "Home" });
            console.log(e);
          })
          .catch((err) => {
            if (err.response.data == "SequelizeUniqueConstraintError") {
              alert("您注册的医疗机构名称已被注册");
            }
          });
        // alert("保存成功");
      }
    },
  },
};
</script>
